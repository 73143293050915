import Header from '../../../components/HeaderList';
import Clients from '../../../assets/sections/clients.png';
import ZonesIcon from '../../../assets/navigation/cobertura.svg';
//import Categories from '../../../assets/sections/categories.png';
// import Drivers from '../../../assets/sections/drivers.png';
import Motos from '../../../assets/sections/motos.png';
import Users from '../../../assets/sections/users.png';
import CardSection from '../../../components/CardSection';
import styles from '../styles/SettingsUI.module.css';

const SettingsUI = () => (
  <div>
    <Header title="Ajustes" />
    <div className={styles.sections}>
      <CardSection image={ZonesIcon} label="ZONAS DE COBERTURA" route="/ajustes/zonas" /> 
      <CardSection image={Clients} label="CLIENTES" route="/ajustes/clientes" />
      <CardSection image={Motos} label="LISTA DE MOTOS" route="/ajustes/motos" />
      {/*
       <CardSection image={Categories} label="CATEGORÍAS" route="/ajustes/categorias" />
       <CardSection image={Drivers} label="DRIVERS" route="/ajustes/drivers" /> 
       */}
      <CardSection image={Users} label="USUARIOS" route="/ajustes/usuarios" />
    </div>
  </div>
);

export default SettingsUI;
