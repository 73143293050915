import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import produce from "immer";
import {
  getAllSlides,
  selectSlides,
  deleteSlide,
  activeBanner,
} from "../../redux/slices/frontpage";
import BannersUI from './components/BannersUI.js';

const Banners = () => {
    const [loading, handleLoading] = useState(true);
    const [editorVisible, handleEditorVisible] = useState(false);
    const [banner, handleBanner] = useState(null);
    const slides = useSelector(selectSlides);
    const dispatch = useDispatch();
  
    const initialFetch = useCallback(async () => {
      const [response] =
        await Promise.all([
          dispatch(getAllSlides())
        ]);
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      }
      handleLoading(false);
    }, []);
  
    useEffect(() => {
      initialFetch();
    }, [initialFetch]);
  
    const editBanner = (newTopping) => {
      handleBanner(newTopping);
      handleEditorVisible(true);
    };
  
    const closeEditor = () => {
      handleEditorVisible(false);
      handleBanner(null);
    };
  
    const newBanner = () => {
      handleBanner(null);
      handleEditorVisible(true);
    };
  
    const handleActive = async (idBanner, index, val) => {
      const response = await dispatch(activeBanner(idBanner, index, val));
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      }
    };
  
    const confirmDelete = (idSlide) => {
      Modal.confirm({
        title: "¿Estás segúro de eliminar este banner?",
        content: "Esta información no se podrá recuperar.",
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        async onOk() {
          await removeBanner(idSlide);
        },
        onCancel() {},
      });
    };
  
    const removeBanner = async (idSlide) => {
      const response = await dispatch(deleteSlide(idSlide));
      if (response.status !== "success") {
        message.error("¡Hubo un problema!");
      } else {
        message.success("Banner eliminado correctamente");
      }
    };


  return (
    <BannersUI
        loading={loading}
        editorVisible={editorVisible}
        editBanner={editBanner}
        closeEditor={closeEditor}
        newBanner={newBanner}
        banner={banner}
        banners={slides}
        handleBanner={handleBanner}
        confirmDelete={confirmDelete}
        handleActive={handleActive}
    />
  );
}

export default Banners;
