import { Row, Col, Button, Modal, Spin, Switch } from "antd";
import cx from "classnames";
import moment from "moment";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Deactivate } from "../../../assets/icons/deactivate.svg";
import { ReactComponent as Add } from "../../../assets/icons/add-round.svg";
import { ReactComponent as Gift } from "../../../assets/icons/regalo.svg";
import Editor from "./Editor";
import styles from "../styles/BannersUI.module.css";

const BannersUI = (props) => {
  const {
    loading,
    editorVisible,
    editBanner,
    newBanner,
    closeEditor,
    banner,
    handleBanner,
    banners,
    handleActive,
    confirmDelete,
  } = props;

  const Banner = ({ slide, index }) => (
    <div className={cx(styles.banner, {
        [styles.banneroff]: !slide.active,
      })}>
      <Row gutter={5} align="middle">
        <Col span={4}>
        <div  className={cx(styles.bannerPhoto, {
              [styles.innactive]: !slide.active,
            })}
            style={{ backgroundImage: `url(${slide.image})` }}
          />
        </Col>
        <Col span={16}>
          <div className={styles.bannerTile}>
            <h3>{slide.name}</h3>
            <span>creado por: administrador</span>
            <span>fecha creacion: {moment(slide.date_created).format('D/M/Y')}</span>
          </div>
        </Col>
        <Col span={2}>
          <div className={styles.bannerActions}>
            <div
              className={styles.actionWrap}
              onClick={() => editBanner(slide)}
            >
              <Edit />
            </div>
          </div>
        </Col>
        <Col span={2}>
        <div className={styles.bannerActions}>
            <Switch checked={slide.active} onChange={() => handleActive(slide.id, index, slide.active)} />
            </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className={styles.view}>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <h1>Edición de banners</h1>
            <div className={styles.actions}>
              <Button onClick={newBanner} size="large">
                NUEVO BANNER
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {!loading ? (
        <Row gutter={20} className={styles.toppingsWrap}>
          <Col span={24} style={{ marginLeft: "2rem" }}>
                <Row>
                    {banners ? (
                        <>
                            <Col span={24}>
                            {banners.map((slide, index) => (
                                <Banner key={slide.id} slide={slide} index={index} />
                            ))}
                            </Col>
                        </>
                    ) : null}
                </Row>
            </Col>     
        </Row>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}

      <Modal
        destroyOnClose
        visible={editorVisible}
        footer={false}
        closable={false}
        width={600}
        onCancel={closeEditor}
      >
        <Editor
          banner={banner}
          closeEditor={closeEditor}
          handleBanner={handleBanner}
        />
      </Modal>
    </div>
  );
};

export default BannersUI;
